<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1
								v-if="Object.keys(intrestedPartner).length !== 0"
								class="header-title"
								>
								{{ fullName }}
							</h1>
							<span v-if="loadingPartner">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<router-link
				to="/partners/intrested-partners"
				class="btn btn-light mb-4 btn-sm"
				>
				Go back
			</router-link>
			<div class="row">
				<div class="col-12 col-xl-8">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Partner Information</h4>
						</div>
						<div
							v-if="loadingPartner"
							class="d-flex justify-content-center align-items-center py-3"
							>
							<span>Loading...</span>
						</div>
						<div
							class="card-body p-2"
							v-if="Object.keys(intrestedPartner).length !== 0"
							>
							<div class="list-group list-group-flush mb-4">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Name</span>
										</div>
										<div class="col-auto">
											<span
												v-if="
													intrestedPartner?.fname || intrestedPartner?.lname
												"
												class=""
												>
												{{ fullName }}</span
												>
											<span v-else class="">N/A</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>Company Name</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{
												intrestedPartner?.company_name ?? 'N/A'
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>Email Address</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{
												intrestedPartner?.email ?? 'N/A'
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Phone</span>
										</div>
										<div class="col-auto">
											<span class="">{{
												intrestedPartner?.phone ?? 'N/A'
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>Vehicle Brand</span
												>
										</div>
										<div class="col-auto">
											<span
												v-if="
													intrestedPartner?.vehicle_brand ||
														intrestedPartner?.vehicle_type
												"
												class=""
												>{{ vehicle }}</span
												>
											<span v-else class="">N/A</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>Vehicle Count</span
												>
										</div>
										<div class="col-auto">
											<span class="">{{
												intrestedPartner?.vehicle_count ?? 'N/A'
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase"
												>Vehicle Amenities</span
												>
										</div>
										<div
											v-if="intrestedPartner?.vehicle_amenities"
											class="col-auto"
											>
											<span
												class="border text-muted m-1 rounded p-1"
												v-for="(
													amenity, index
												) in intrestedPartner?.vehicle_amenities"
												:key="index"
												>{{ amenity }}</span
												>
										</div>
										<div v-else class="col-auto">
											<span class="">N/A</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'IntrestedPartnerDetails',
  data () {
    return {
      intrestedPartner: {},
      loadingPartner: false
    }
  },
  created () {
    this.fetchPartner()
  },
  methods: {
    fetchPartner () {
      this.loadingPartner = true
      this.axios
        .get(`/v1/partner-interests/${this.$route.params.partnerId}`)
        .then((res) => {
          this.intrestedPartner = res.data
          this.loadingPartner = false
        })
        .catch(() => {
          this.loadingPartner = false
          this.$toastr.e('Something went wrong', 'Error')
        })
        .finally(() => {
          this.loadingPartner = false
        })
    }
  },
  computed: {
    fullName () {
      if (this.intrestedPartner) {
        return this.intrestedPartner?.fname + ' ' + this.intrestedPartner?.lname
      }
      return 'N/A'
    },

    vehicle () {
      if (this.intrestedPartner) {
        return (
          this.intrestedPartner?.vehicle_brand +
          ' ' +
          this.intrestedPartner?.vehicle_type
        )
      }
      return 'N/A'
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #01e069 !default;
$black: #060606 !default;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}

.nav-link.active {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
